<template>
  <div class="d-flex align-center jobs-overview-scroll-menu">
    <strong>{{ numJobs }} {{ $tc('jobs.joboffers', numJobs) }}</strong>
    <span class="ml-auto">
      <jobalert-dialog></jobalert-dialog>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import jobalertDialog from '@/components/jobalert/signup-dialog.vue';

export default {
  computed: {
    ...mapGetters('jobsModule', {
      joboffers: 'getJoboffers'
    }),

    visibleJoboffers() {
      return this.joboffers.filter((x) => !x.isBlacklisted);
    },

    numJobs() {
      return this.visibleJoboffers.length;
    }
  },

  components: {
    jobalertDialog
  }
};
</script>
