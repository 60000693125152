<template>
  <div
    v-if="endDate !== ''"
    class="joboffer-box__job-data mr-sm-8 mb-2 mb-sm-0 d-flex align-center"
  >
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <v-icon class="d-inline">mdi-calendar</v-icon>
          <div class="d-inline-block">
            {{ endDate }}
          </div>
        </div>
      </template>

      <span>{{ $t('jobs.applicationPeriodEndDate') }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    joboffer: {
      type: Object,
      require: true
    }
  },

  computed: {
    endDate() {
      if (!this.joboffer.endDateUtc) {
        return '';
      }
      const dateTimeParts = this.joboffer.endDateUtc.split('T');
      const dateItems = dateTimeParts[0].split('-');

      if (dateItems.length !== 3) {
        return '';
      }

      const date = new Date(dateItems[0], dateItems[1] - 1, dateItems[2]);
      return this.$d(date, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
    }
  }
};
</script>
